import "./portfolio-pages.css";
import {
  birdsImages,
  macroInsectImages,
} from "../imageUrlLists/wildlifeImagesList";

function WildLifePortfolio() {
  return (
    <div className="container-custom">
      <h1>Wild Life</h1>
      <div>
        <div>
          <h1>Macro photos</h1>
        </div>
        <div className="img-container">
          {macroInsectImages.map((image) => {
            return (
              <img
                src={image.src}
                alt=""
                style={{
                  width: image.width ?? "",
                  height: image.height ?? "",
                }}
              />
            );
          })}
        </div>
      </div>

      <div>
        <h1>Birds</h1>
      </div>
      <div className="img-container">
        {birdsImages.map((image) => {
          return <img src={image.src} alt="" />;
        })}
      </div>
    </div>
  );
}

export default WildLifePortfolio;
