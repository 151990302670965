import neuschwansteinImage1 from "../assets/neuschwanstein_pictures/Schloss Neuschwanstein-3.jpg";
import neuschwansteinImage2 from "../assets/neuschwanstein_pictures/Schloss Neuschwanstein-7.jpg";
import neuschwansteinImage3 from "../assets/neuschwanstein_pictures/Schloss Neuschwanstein-8.jpg";
import neuschwansteinImage4 from "../assets/neuschwanstein_pictures/Schloss Neuschwanstein-9.jpg";
import neuschwansteinImage5 from "../assets/neuschwanstein_pictures/Schloss Neuschwanstein-10.jpg";
import neuschwansteinImage6 from "../assets/neuschwanstein_pictures/Schloss Neuschwanstein-12.jpg";
import neuschwansteinImage7 from "../assets/neuschwanstein_pictures/Schloss Neuschwanstein-15.jpg";

export const neuschwansteinImages = [
  {
    src: neuschwansteinImage1,
  },
  {
    src: neuschwansteinImage2,
  },
  {
    src: neuschwansteinImage4,
    width: "680px",
    height: "450px",
  },
  {
    src: neuschwansteinImage7,
  },
  {
    src: neuschwansteinImage3,
  },
  {
    src: neuschwansteinImage5,
  },
  {
    src: neuschwansteinImage6,
  },
];
