import "./portfolio-pages.css";
import {
  forestImages,
  natureImages,
} from "../imageUrlLists/natureForestImagesList";

function NatureForestPortfolio() {
  return (
    <div className="container-custom">
      <h1>Flowers and Forest</h1>
      <div>
        <h2>Flowers</h2>
      </div>
      <div className="img-container">
        {natureImages.map((image) => {
          return (
            <img
              src={image.src}
              alt=""
              style={{
                width: image.width ?? "",
                height: image.height ?? "",
              }}
            />
          );
        })}
      </div>
      <div>
        <h2>Forest</h2>
      </div>
      <div className="img-container">
        {forestImages.map((image) => {
          return (
            <img
              src={image.src}
              alt=""
              style={{
                width: image.width ?? "",
                height: image.height ?? "",
              }}
            />
          );
        })}
      </div>
    </div>
  );
}

export default NatureForestPortfolio;
