import "./portfolio-pages.css";
import {
  nightStreetImages,
  streetImages,
} from "../imageUrlLists/streetImagesList";

function StreetPortfolio() {
  return (
    <div className="container-custom">
      <h1>Street Photos</h1>
      <div className="img-container">
        {streetImages.map((image) => {
          return <img src={image.src} alt="" />;
        })}
        <div>
          <h1>Street & urban photos at night</h1>
        </div>
        <div className="img-container">
          {nightStreetImages.map((image) => {
            return (
              <img
                src={image.src}
                alt=""
                style={{
                  width: image.width ?? "",
                  height: image.height ?? "",
                }}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default StreetPortfolio;
