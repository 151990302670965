import "./portfolio-pages.css";
import { londonImages } from "../imageUrlLists/londonImagesList";

function LondonPortfolio() {
  return (
    <div className="container-custom">
      <h1>London</h1>
      <div className="img-container">
        {londonImages.map((image) => {
          return (
            <img
              src={image.src}
              alt=""
              style={{
                width: image.width ?? "",
                height: image.height ?? "",
              }}
            />
          );
        })}
      </div>
    </div>
  );
}

export default LondonPortfolio;
