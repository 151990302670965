import streetImage1 from "../assets/street_photos/Augsburg Stadt 2-min.jpg";
import streetImage2 from "../assets/street_photos/Augsburg Stadt Fahrrad 2-min.jpg";
import streetImage3 from "../assets/street_photos/Street Ausgburg-27-min.jpg";
import streetImage4 from "../assets/street_photos/Schaufenster Reflection-min.jpg";
import streetImage5 from "../assets/street_photos/Street Stadt Wald Augsburg-17-mi.jpg";
import streetImage6 from "../assets/street_photos/Street Stadt Wald Augsburg-20-mi.jpg";
import streetImage7 from "../assets/street_photos/Street Stadt Wald Augsburg-21-mi.jpg";
import streetImage8 from "../assets/street_photos/Street Stadt Wald Augsburg-26-mi.jpg";
import streetImage9 from "../assets/street_photos/Street Stadt Wald Augsburg-63-mi.jpg";
import streetImage10 from "../assets/street_photos/Street Stadt Wald Augsburg-69-mi.jpg";
import streetImage11 from "../assets/street_photos/Street Stadt Wald Augsburg-3-min.jpg";
import streetImage12 from "../assets/street_photos/Street Stadt Wald Augsburg-59-mi.jpg";
import streetImage13 from "../assets/street_photos/Street Stadt Wald Augsburg-73-mi.jpg";
import streetImage14 from "../assets/street_photos/Streetphoto weniger scharf-34-mi.jpg";
import streetImage15 from "../assets/street_photos/Streetphoto-30-min.jpg";
import streetImage16 from "../assets/street_photos/Streetphoto-27-min.jpg";
import streetImage17 from "../assets/street_photos/Streetphoto-31-min.jpg";
import streetImage18 from "../assets/street_photos/Street Ausgburg-13 (1).jpg";
import streetImage19 from "../assets/street_photos/Augsburg Stadt 1 (1).jpg";
import streetImage20 from "../assets/street_photos/Augsburg Stadt Statue 2 (1).jpg";
import nightStreet1 from "../assets/street_photos/plärrer/Plärrer-1.jpg";
import nightStreet2 from "../assets/street_photos/plärrer/Plärrer-12.jpg";
import nightStreet3 from "../assets/street_photos/plärrer/Plärrer-13.jpg";
import nightStreet4 from "../assets/street_photos/plärrer/Plärrer-14.jpg";
import nightStreet5 from "../assets/street_photos/plärrer/Plärrer-5.jpg";
import nightStreet6 from "../assets/street_photos/plärrer/Plärrer-6.jpg";
import nightStreet7 from "../assets/street_photos/plärrer/Plärrer-7.jpg";
import nightStreet8 from "../assets/street_photos/plärrer/Plärrer-9.jpg";

export const streetImages = [
  {
    src: streetImage1,
  },
  {
    src: streetImage2,
  },
  {
    src: streetImage3,
  },
  {
    src: streetImage4,
  },
  {
    src: streetImage5,
  },
  {
    src: streetImage6,
  },
  {
    src: streetImage7,
  },
  {
    src: streetImage8,
  },
  {
    src: streetImage9,
  },
  {
    src: streetImage10,
  },
  {
    src: streetImage11,
  },
  {
    src: streetImage12,
  },
  {
    src: streetImage13,
  },
  {
    src: streetImage14,
  },
  {
    src: streetImage15,
  },
  {
    src: streetImage16,
  },
  {
    src: streetImage17,
  },

  {
    src: streetImage18,
  },

  {
    src: streetImage19,
  },

  {
    src: streetImage20,
  },
];

export const nightStreetImages = [
  {
    src: nightStreet1,
  },

  {
    src: nightStreet2,
  },

  {
    src: nightStreet3,
  },

  {
    src: nightStreet4,
  },

  {
    src: nightStreet5,
    width: "710px",
    height: "450px",
  },

  {
    src: nightStreet6,
  },
  {
    src: nightStreet7,
  },
  {
    src: nightStreet8,
  },
];
