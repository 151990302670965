import nature1 from "../assets/forest_and_nature_pictures/flowers/Botanischer Garten-1.jpg";
import nature2 from "../assets/forest_and_nature_pictures/flowers/Botanischer Garten-3.jpg";
import nature3 from "../assets/forest_and_nature_pictures/flowers/Blumen-3-min.jpg";
import nature4 from "../assets/forest_and_nature_pictures/flowers/Botanischer Garten-14.jpg";
import nature5 from "../assets/forest_and_nature_pictures/flowers/Botanischer Garten-8.jpg";
import nature6 from "../assets/forest_and_nature_pictures/flowers/Botanischer Garten-11.jpg";
import nature7 from "../assets/forest_and_nature_pictures/flowers/Botanischer Garten-15.jpg";
import nature8 from "../assets/forest_and_nature_pictures/flowers/Botanischer Garten-16.jpg";
import nature9 from "../assets/forest_and_nature_pictures/flowers/Blumen-2-min.jpg";
import nature10 from "../assets/forest_and_nature_pictures/flowers/Blumen-12-min.jpg";
import nature11 from "../assets/forest_and_nature_pictures/flowers/Blumen-19-min.jpg";
import nature12 from "../assets/forest_and_nature_pictures/flowers/Botanischer Garten-6.jpg";
import nature13 from "../assets/forest_and_nature_pictures/flowers/Firnhaberauheide Gersthofen-4-min.jpg";
import nature14 from "../assets/forest_and_nature_pictures/flowers/Blume 5.jpg";
import forest1 from "../assets/forest_and_nature_pictures/Augsburg Siebentischwald-29.jpg";
import forest2 from "../assets/forest_and_nature_pictures/Augsburg Siebentischwald-31-min.jpg";
import forest3 from "../assets/forest_and_nature_pictures/Augsburg Siebentischwald-33-min.jpg";
import forest4 from "../assets/forest_and_nature_pictures/Wandertour Nordic-15-min.jpg";
import forest5 from "../assets/forest_and_nature_pictures/Wandertour Nordic-17-min.jpg";
import forest6 from "../assets/forest_and_nature_pictures/Wandertour Nordic-23-min.jpg";
import forest7 from "../assets/forest_and_nature_pictures/Wandertour Nordic-28-min.jpg";
import forest8 from "../assets/forest_and_nature_pictures/Wandertour Nordic-35-min.jpg";
import forest9 from "../assets/forest_and_nature_pictures/Wandertour Nordic-39 (1).jpg";
import forest10 from "../assets/forest_and_nature_pictures/Wandertour Nordic-33 (1).jpg";
import forest11 from "../assets/forest_and_nature_pictures/Wandertour Nordic-9-min.jpg";
import forest12 from "../assets/forest_and_nature_pictures/Wandertour Nordic-37-min.jpg";
import forest13 from "../assets/forest_and_nature_pictures/Westliche Wälder-3-min.jpg";
import forest14 from "../assets/forest_and_nature_pictures/Westliche Wälder-5-min.jpg";
import forest15 from "../assets/forest_and_nature_pictures/Westliche Wälder-7-min.jpg";
import forest16 from "../assets/forest_and_nature_pictures/efeu-min.jpg";
import forest17 from "../assets/forest_and_nature_pictures/Forest1.jpg";
import forest18 from "../assets/forest_and_nature_pictures/Forest2.jpg";
import forest19 from "../assets/forest_and_nature_pictures/plant with raindrop-min.jpg";
import forest20 from "../assets/forest_and_nature_pictures/Westliche Wälder-13-min.jpg";
import forest21 from "../assets/forest_and_nature_pictures/Westliche Wälder-14-min.jpg";
import forest22 from "../assets/forest_and_nature_pictures/Westliche Wälder-9-min.jpg";
import forest23 from "../assets/forest_and_nature_pictures/fischach/Wanderung Winter-11.jpg";
import forest24 from "../assets/forest_and_nature_pictures/fischach/Wanderung Winter-5.jpg";
import forest25 from "../assets/forest_and_nature_pictures/fischach/Wanderung Winter-12.jpg";
import forest26 from "../assets/forest_and_nature_pictures/fischach/Wanderung Winter-9.jpg";

export const natureImages = [
  {
    src: nature1,
    width: "600px",
    height: "450px",
  },
  {
    src: nature2,
  },
  {
    src: nature3,
  },
  {
    src: nature4,
  },
  {
    src: nature5,
  },
  {
    src: nature6,
  },
  {
    src: nature7,
  },

  {
    src: nature8,
  },

  {
    src: nature9,
  },

  {
    src: nature10,
  },

  {
    src: nature11,
  },

  {
    src: nature12,
  },

  {
    src: nature13,
  },
  {
    src: nature14,
    width: "300px",
    height: "450px",
  },
];

export const forestImages = [
  {
    src: forest1,
  },
  {
    src: forest2,
  },
  {
    src: forest3,
  },
  {
    src: forest4,
  },
  {
    src: forest5,
  },
  {
    src: forest6,
  },
  {
    src: forest7,
  },

  {
    src: forest8,
  },

  {
    src: forest9,
  },

  {
    src: forest10,
  },

  {
    src: forest11,
    width: "720px",
    height: "450px",
  },

  {
    src: forest12,
  },

  {
    src: forest13,
    width: "730px",
    height: "450px",
  },
  {
    src: forest14,
    width: "320px",
    height: "450px",
  },
  {
    src: forest15,
    width: "320px",
    height: "450px",
  },
  {
    src: forest16,
  },
  {
    src: forest17,
  },
  {
    src: forest18,
  },
  {
    src: forest19,
  },
  {
    src: forest20,
  },
  {
    src: forest21,
  },

  {
    src: forest22,
  },

  {
    src: forest23,
  },

  {
    src: forest24,
  },

  {
    src: forest25,
  },

  {
    src: forest26,
  },
];
